import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Ot, SelectionQuery } from "../../graphql-types";
import { usePageContext } from "../components/context/PageContext";
import { CardOt } from "../components/card/CardOt";
import { BottomNav } from "../components/layout/BottomNav";
import * as styles from "../styles/pages/selection.module.scss";
import { Filters } from "../components/filters/Filters";
import { shuffleArray } from "../utils/shuffleArray";
import { SEO } from "../components/seo/seo";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";

interface Props {
  data: SelectionQuery;
  pageContext: any;
}

const SelectionTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { lang } = usePageContext();
  const { sanitySelection, allOt, sanityFavoritePage, sanityEventsPage } = data;
  const [otsFiltered, setOtsFiltered] = useState<Ot[]>(
    allOt.nodes
      .sort((item1, item2) =>
        (item1.libelle?.fr ?? "").localeCompare(item2.libelle?.fr ?? "")
      )
      .filter(ot => ot.preFilters?.includes(pageContext.preFilters)) as Ot[]
  );

  return (
    <>
      <SEO rawMeta={sanitySelection?._rawMetadata} />
      <div className={styles.wrapper}>
        <BreadCrumb
          label={sanityEventsPage?.data?._rawTitleBreadcrumb?.[lang]}
          labelUrl={"/event"}
          label2={sanitySelection?.content?._rawTitle?.[lang]}
        />
        <header>
          <div className="desktopCentered">
            <h2>{sanitySelection?.content?._rawTitle?.[lang]}</h2>
            {/* <BlockContent
            blocks={sanitySelection?.content?._rawDescription?.[lang]}
          /> */}
          </div>
        </header>
        <Filters
          ots={allOt.nodes as Ot[]}
          otsFiltered={otsFiltered}
          setOtsFiltered={setOtsFiltered}
          filters={sanitySelection?.content?.filters as string[]}
        />
        <section>
          <div
            className={styles.result}
          >{`${otsFiltered.length} ${sanityFavoritePage?.data?._rawResultLabel?.[lang]}`}</div>
          <div className={styles.otsContainer}>
            {otsFiltered.map(item => (
              <React.Fragment key={item.id}>
                <CardOt
                  ot={item as Ot}
                  pathSelection={pageContext.pathSelection}
                />
              </React.Fragment>
            ))}
          </div>
        </section>
        <BottomNav
          prev={pageContext.prev}
          next={pageContext.next}
          current={sanitySelection?.content?._rawTitle}
        />
      </div>
    </>
  );
};

export const QUERY = graphql`
  query Selection($id: String, $selections: [String]) {
    sanitySelection(id: { eq: $id }) {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      content {
        preFilters
        filters
        _rawDescription
        _rawTitle
        img {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }

    allOt(filter: { selections: { elemMatch: { id: { in: $selections } } } }) {
      nodes {
        id
        preFilters
        selections {
          id
        }
        medias {
          resolutions {
            url
            type
          }
        }
        codeInsee
        descriptifs {
          contenu {
            fr
            en
          }
        }
        libelle {
          en
          fr
        }
        criteres {
          id
          libelle {
            en
            fr
          }
          valeurs {
            id
            libelle {
              en
              fr
            }
            valorisation {
              valeur
            }
          }
        }
        filters
      }
    }

    sanityFavoritePage {
      data {
        _rawResultLabel
      }
    }

    sanityEventsPage {
      data {
        _rawTitleBreadcrumb
      }
    }
  }
`;

export default SelectionTemplate;
