import React, { useState, useEffect } from "react";
import { Ot } from "../../../graphql-types";
import { CategoryFilterDisplay, filterCategory } from "./CategoryFilter";
import { CritereFilterDisplay, filterType } from "./CritereFilter";
import * as styles from "./filters.module.scss";
import { filterGps, GpsFilterDisplay } from "./GpsFilter";
import { SERVICESFILTERS } from "./ServicesFilter";
import {
  filterCapacity,
  getCapaciteCouvert,
  getCapaciteTheatre,
  SliderFilterDisplay,
} from "./SliderFilter";
import { THEMATIQUEFILTERS } from "./ThematiqueFilter";
import { getMaxCapaciteCouvert, getMaxCapaciteTheatre } from "./SliderFilter";
import { usePageContext } from "../context/PageContext";

interface Props {
  ots: Ot[];
  otsFiltered: Ot[];
  setOtsFiltered: (list: Ot[]) => void;
  filters: string[];
}

export interface FilterProps {
  filter: string[];
  setFilter: (value: string[]) => void;
}

export const Filters: React.FC<Props> = ({
  ots,
  otsFiltered,
  setOtsFiltered,
  filters,
}) => {
  const { lang } = usePageContext();
  const [categorySelected, setCategory] = useState<string[]>([]);
  const [gpsSelected, setGps] = useState<string[]>([]);
  const [servicesSelected, setServices] = useState<string[]>([]);
  const [thematiqueSelected, setThematique] = useState<string[]>([]);
  const [capaTheatreSelected, setCapaTheatre] = useState<number>(0);
  const [capaCouvertSelected, setCapaCouvert] = useState<number>(0);

  const filter = () => {
    let otsFilteredTmp = ots;

    otsFilteredTmp =
      categorySelected.length === 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterCategory(filters, ot, categorySelected)
          );

    otsFilteredTmp =
      gpsSelected.length === 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterGps(filters, ot, gpsSelected, lang)
          );

    otsFilteredTmp =
      servicesSelected.length === 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterType(SERVICESFILTERS, ot, servicesSelected)
          );

    otsFilteredTmp =
      thematiqueSelected.length === 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterType(THEMATIQUEFILTERS, ot, thematiqueSelected)
          );

    otsFilteredTmp =
      capaCouvertSelected !== 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterCapacity(capaCouvertSelected, ot, getCapaciteCouvert)
          );

    otsFilteredTmp =
      capaTheatreSelected !== 0
        ? otsFilteredTmp
        : otsFilteredTmp.filter(ot =>
            filterCapacity(capaTheatreSelected, ot, getCapaciteTheatre)
          );

    setOtsFiltered(otsFilteredTmp);
  };

  useEffect(() => {
    filter();
  }, [
    categorySelected,
    gpsSelected,
    thematiqueSelected,
    servicesSelected,
    capaCouvertSelected,
    capaTheatreSelected,
  ]);

  return (
    <div>
      {filters.includes("geozone") && (
        <GpsFilterDisplay filter={gpsSelected} setFilter={setGps} />
      )}
      {filters.includes("Categorie") && (
        <CategoryFilterDisplay
          filter={categorySelected}
          setFilter={setCategory}
        />
      )}
      {filters.includes("TypeService") && (
        <CritereFilterDisplay
          filter={servicesSelected}
          setFilter={setServices}
          filterValues={SERVICESFILTERS}
        />
      )}
      {filters.includes("Thematique") && (
        <CritereFilterDisplay
          filter={thematiqueSelected}
          setFilter={setThematique}
          filterValues={THEMATIQUEFILTERS}
        />
      )}
      {filters.includes("NombreMaxCouvert") && (
        <SliderFilterDisplay
          filter={capaCouvertSelected}
          setFilter={setCapaCouvert}
          maxValue={getMaxCapaciteCouvert(ots)}
        />
      )}
      {filters.includes("CapaciteMaxTheatre") && (
        <SliderFilterDisplay
          filter={capaTheatreSelected}
          setFilter={setCapaTheatre}
          maxValue={getMaxCapaciteTheatre(ots)}
        />
      )}
    </div>
  );
};

export const toggleFilter = (
  value: string,
  filter: string[],
  setFilter: Function
): void => {
  const index = filter.findIndex(item => item === value);
  let arrCopy = [...filter];
  if (index !== -1) {
    arrCopy.splice(index, 1);
    setFilter(arrCopy);
  } else setFilter([...filter, value]);
};
