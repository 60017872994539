export const THEMATIQUEFILTERS = [
  {
    libelle: { fr: "Défi / Jeux / Sports", en: "Challenge / Games / Sports" },
    criteres: [
      "GYROPODE",
      "CANOKAYAK",
      "MULTISPORT",
      "ACCROBRANCHE",
      "PADDLE",
      "QUAD",
      "MOTOCROSS",
      "BOWLING",
      "LASER",
      "KARTING",
      "PARCTHMES",
      "HLICOPTRE",
      "MONTGOLFIRE",
      "CYCLOTOURISME",
    ],
  },
  {
    libelle: { fr: "Gastronomie", en: "Gastronomy" },
    criteres: ["GASTRONOMIE", "OENOLOGIE", "COURSDECUISINE", "DGUSTATION"],
  },
  {
    libelle: { fr: "Création / Enigme", en: "Creation / Enigmes" },
    criteres: ["ESCAPEGAME"],
  },
];
