import React from "react";
import { Ot } from "../../../graphql-types";
import { FilterProps, toggleFilter } from "./Filters";
import * as styles from "./filters.module.scss";
import { usePageContext } from "../context/PageContext";

export const GpsFilterDisplay: React.FC<FilterProps> = ({
  filter,
  setFilter,
}) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.filter}>
      {filterValues.map(item => (
        <div
          key={`filter-loc-${item?.libelle?.[lang] ?? ""}`}
          onClick={() => toggleFilter(item.libelle.fr, filter, setFilter)}
          className={
            filter.find(filterSelected => filterSelected === item.libelle.fr) &&
            styles.selected
          }
        >
          {item.libelle?.[lang] ?? item.libelle?.fr}
        </div>
      ))}
    </div>
  );
};

export const filterGps = (
  filters: string[],
  ot: Ot,
  selectedFilters: string[],
  lang: string
): boolean => {
  if (selectedFilters.length === 0) return true;
  return filterValues
    .map(item => {
      if (!selectedFilters.includes(item.libelle?.[lang] ?? item.libelle?.fr))
        return false;
      return item.codes.includes(ot.codeInsee ?? "");
    })
    .includes(true);
};

const filterValues = [
  {
    libelle: { fr: "Tours", en: "Tours" },
    codes: ["37261"],
  },
  {
    libelle: { fr: "Tours Métropole", en: "Metropolis of Tours" },
    codes: [
      "37018",
      "37025",
      "37050",
      "37054",
      "37099",
      "37109",
      "37122",
      "37151",
      "37195",
      "37139",
      "37152",
      "37172",
      "37179",
      "37203",
      "37208",
      "37214",
      "37217",
      "37219",
      "37233",
      "37243",
      "37272",
    ],
  },
  {
    libelle: { fr: "Autour de Tours", en: "Around Tours" },
    codes: [
      "37001",
      "37002",
      "37003",
      "37004",
      "37005",
      "37006",
      "37007",
      "37008",
      "37009",
      "37010",
      "37011",
      "37012",
      "37013",
      "37014",
      "37015",
      "37016",
      "37019",
      "37020",
      "37022",
      "37021",
      "37023",
      "37024",
      "37026",
      "37027",
      "37028",
      "37029",
      "37031",
      "37032",
      "37033",
      "37034",
      "37035",
      "37036",
      "37037",
      "37038",
      "37039",
      "37040",
      "37041",
      "37042",
      "37043",
      "37046",
      "37047",
      "37048",
      "37049",
      "37051",
      "37052",
      "37053",
      "37055",
      "37059",
      "37060",
      "37061",
      "37062",
      "37063",
      "37064",
      "37065",
      "37066",
      "37067",
      "37068",
      "37069",
      "37070",
      "37071",
      "37072",
      "37073",
      "37074",
      "37075",
      "37076",
      "37077",
      "37078",
      "37079",
      "37080",
      "37081",
      "37082",
      "37083",
      "37084",
      "37085",
      "37086",
      "37087",
      "37088",
      "37089",
      "37090",
      "37092",
      "37093",
      "37094",
      "37095",
      "37115",
      "37096",
      "37097",
      "37098",
      "37100",
      "37101",
      "37103",
      "37104",
      "37105",
      "37107",
      "37108",
      "37110",
      "37111",
      "37112",
      "37117",
      "37118",
      "37120",
      "37121",
      "37044",
      "37045",
      "37056",
      "37057",
      "37058",
      "37091",
      "37106",
      "37114",
      "37202",
      "37260",
      "37273",
      "37123",
      "37124",
      "37030",
      "37113",
      "37127",
      "37136",
      "37184",
      "37125",
      "37126",
      "37102",
      "37116",
      "37128",
      "37129",
      "37130",
      "37119",
      "37131",
      "37132",
      "37133",
      "37134",
      "37135",
      "37137",
      "37138",
      "37140",
      "37141",
      "37142",
      "37143",
      "37144",
      "37145",
      "37146",
      "37147",
      "37148",
      "37149",
      "37150",
      "37153",
      "37154",
      "37155",
      "37156",
      "37157",
      "37158",
      "37159",
      "37160",
      "37161",
      "37162",
      "37163",
      "37165",
      "37166",
      "37167",
      "37168",
      "37169",
      "37170",
      "37171",
      "37173",
      "37174",
      "37175",
      "37176",
      "37177",
      "37178",
      "37180",
      "37181",
      "37182",
      "37183",
      "37185",
      "37186",
      "37187",
      "37188",
      "37189",
      "37190",
      "37191",
      "37192",
      "37193",
      "37194",
      "37196",
      "37197",
      "37198",
      "37199",
      "37200",
      "37201",
      "37204",
      "37205",
      "37206",
      "37207",
      "37209",
      "37210",
      "37211",
      "37213",
      "37212",
      "37226",
      "37216",
      "37218",
      "37220",
      "37221",
      "37222",
      "37223",
      "37224",
      "37225",
      "37227",
      "37228",
      "37229",
      "37230",
      "37231",
      "37232",
      "37234",
      "37236",
      "37237",
      "37238",
      "37240",
      "37241",
      "37242",
      "37244",
      "37245",
      "37246",
      "37247",
      "37248",
      "37249",
      "37250",
      "37251",
      "37252",
      "37253",
      "37254",
      "37255",
      "37256",
      "37257",
      "37258",
      "37259",
      "37262",
      "37263",
      "37264",
      "37265",
      "37266",
      "37267",
      "37268",
      "37269",
      "37270",
      "37271",
      "37274",
      "37275",
      "37276",
      "37277",
      "37278",
      "37279",
      "37280",
      "37281",
      "37282 ",
    ],
  },
];
