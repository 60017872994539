export const SERVICESFILTERS = [
  {
    libelle: { fr: "Transports", en: "Transports" },
    criteres: ["MINIBUS", "COMMERCES", "AUTOCARS", "VOITURE", "TAXI"],
  },
  {
    libelle: { fr: "Traiteurs", en: "Traiteurs" },
    criteres: ["TRAITEUR"],
  },
  {
    libelle: { fr: "Technique & logistique", en: "Technique & logistique" },
    criteres: ["TECHETLOG"],
  },
  {
    libelle: { fr: "Agences évènementielles", en: "Agences évènementielles" },
    criteres: ["AGENCESEVENT", "AGENCETEAMBUILDING"],
  },
  {
    libelle: { fr: "Autre services", en: "Autre services" },
    criteres: [],
  },
];
