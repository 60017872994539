import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Ot } from "../../../graphql-types";
import { FilterProps, toggleFilter } from "./Filters";
import * as styles from "./filters.module.scss";

export const CategoryFilterDisplay: React.FC<FilterProps> = ({
  filter,
  setFilter,
}) => (
  <div className={styles.filter}>
    <div
      onClick={() => toggleFilter("CATEGORIE|2TOILES", filter, setFilter)}
      className={
        filter.find(item => item === "CATEGORIE|2TOILES") && styles.selected
      }
    >
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
    </div>
    <div
      onClick={() => toggleFilter("CATEGORIE|3TOILES", filter, setFilter)}
      className={
        filter.find(item => item === "CATEGORIE|3TOILES") && styles.selected
      }
    >
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
    </div>
    <div
      onClick={() => toggleFilter("CATEGORIE|4TOILES", filter, setFilter)}
      className={
        filter.find(item => item === "CATEGORIE|4TOILES") && styles.selected
      }
    >
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
    </div>
    <div
      onClick={() => toggleFilter("CATEGORIE|5TOILES", filter, setFilter)}
      className={
        filter.find(item => item === "CATEGORIE|5TOILES") && styles.selected
      }
    >
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
    </div>
  </div>
);

export const filterCategory = (
  filters: string[],
  ot: Ot,
  categoryFilters: string[]
): boolean =>
  ot?.criteres?.find(critere => {
    if (
      categoryFilters.length === 0
      //        || !filters.find(item => item === "Categorie")
    )
      return true;
    if (
      critere?.valeurs?.find(valeur =>
        categoryFilters?.includes(valeur?.id?.toUpperCase() ?? "")
      )
    )
      return true;
    else return false;
  }) !== undefined;
