import React, { useCallback } from "react";
import { Ot, OtCriteres } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";
import { FilterProps, toggleFilter } from "./Filters";
import * as styles from "./filters.module.scss";

type FilterValue = {
  libelle: any;
  criteres: string[];
};

interface CritereFilterProps {
  filterValues: FilterValue[];
}

export const CritereFilterDisplay: React.FC<
  FilterProps & CritereFilterProps
> = ({ filter, setFilter, filterValues }) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.filter}>
      {filterValues.map(item => (
        <div
          key={`filter-crit-${item.libelle.fr}`}
          onClick={() => toggleFilter(item.libelle.fr, filter, setFilter)}
          className={
            filter.find(filterSelected => filterSelected === item.libelle.fr) &&
            styles.selected
          }
        >
          {item.libelle?.[lang] ?? item.libelle?.fr}
        </div>
      ))}
    </div>
  );
};

export const filterType = (
  filterValues: FilterValue[],
  ot: Ot,
  selectedFilters: string[]
): boolean => {
  return filterValues
    .map(item => {
      if (!selectedFilters.includes(item.libelle.fr)) return false;

      return (
        ot?.filters?.find(filter => item.criteres.includes(filter ?? "")) !==
        undefined
      );
    })
    .includes(true);
};
